.horizontal-scroll{
	overflow-x: scroll;
}
.green{
	color : #008000;
}
.red{
	color : red;
}
.pull_right{
	float:right;
}
.center{
	text-align:center;
}
.yellow{
	background-color:#FFFF00;
}
.blue{
	background-color:#0099ff;
}

.md_input_container_dash{
	width:100% !important;
	margin: 8px 0px !important;
}
.dash_heading{
	font-size: 21px;
margin: 20px 0px;
color: #00b0c8;
font-family: 'Noto Sans', sans-serif;
}
.dash_sub_heading{
font-size: 16px;
margin: 0;
color: #00b0c8;
position: relative;
top: -19px;
left: 17px;
width: 50%;
font-family: Noto Sans;
}
.values_in_italic{
	font-size:14px !important;
	    color: #7c7575;
}
.arrow_icon{
	font-size:14px !important;
	float:right;	
/* margin-left:25%; */
height: 12px !important;
width: 12px !important;
min-width: 0px !important;
min-height: 0px !important;
margin: 0px 2px;
}
.head_ym{
	border-bottom: 2px solid #D5D5D5;
color: #424244;
margin-top: 0;
}
.dash_heading_line{
	color: #ea5c48 !important;
margin: 3px 3px 3px 0;
}
 table.simple.dash_table thead tr th{
	min-width: 0px !important;
color: #fd4138;
text-transform: uppercase;
font-size: 14px;
text-align:center;

}
.footer_bottom{
	bottom: 0px;
position: relative;
background: #CFCFCF;
}
.footer_logo{
	width: 100px;
margin: 4px;
}
.footer_content{
	margin: 20px;
}
.footer_content a{
	float:right;
}
.powered_by_content{
	margin: 8px;
font-size: 15px;
}

.dash_table_scroll{
	overflow-x:auto !important;
}
.table_cus_fit
{
width:100%!important;
min-width:100%!important;
overflow: auto;
-ms-overflow-style: scrollbar;}

@media only screen and (max-width :950px)
{
.page-layout > .header .title {
    font-size: 24px;
}
}
.cus_full_width {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}
.cus_flex_full_width
{
	width: 100%;
}
.cus_td_adj{
   position: relative;
    display: inline-block;
    padding-right: 80px;
    width: 100%;
}
.cus_td_adj p {
    text-align: left;
}
.cus_td_adj_icon {
    position: absolute;
    right: 0;
    top: 40%;
}
i.cus_td_wrap{
	font-size: 14px !important;
    margin-right: 7px;
    float: right;
    min-width: auto!important;
    width: auto!important;
    height: auto!important;
    min-height: auto!important;
    top: 2px;
    position: relative;
}
table.simple tbody tr td {
    border-right: 1px solid #e2e2e2!important;
}
.month_cus {
    padding-left: 8px!important;
    padding-right: 8px!important;
    text-align: center;
}
table.simple thead tr th:first-child {
     padding-left: 8px!important; 
}
.cus_first_left th
{
	text-align:left!important;
}
.cus_top_margin {
    margin: 0 8px!important;
}

@media only screen and (max-width :450px)
{
.rzslider .rz-ticks .rz-tick .rz-tick-value {
        font-size: 9px;
}
}

.cus_arrow_wrap td.text-right {
    position: relative;
     padding: 10px 20px!important;
}
.cus_arrow_wrap td.text-right i {
    font-size: 14px!important;
    position: absolute;
    right: 3px;
    top: 30%;
    padding: 0!important;
margin-right: 0!important;
}

button.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}

button.accordion.active, button.accordion:hover {
    background-color: #ddd;
}

button.accordion:after {
    content: '\02795';
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}

button.accordion.active:after {
    content: "\2796";
}

div.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: 0.6s ease-in-out;
    opacity: 0;
}

div.panel.show {
    opacity: 1;
    max-height: 500px;  
}
.daily_sales_card_maxheight .trend_sector .trend_bg {
    padding: 5px;
}
.daily_sales_card_maxheight .trend_sector:nth-child(odd)>.trend_bg {
    background-color: #f9f9f9;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    width: 8px!important;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: #f15d22!important;
}
.bg1
{
	background: url(/assets/images/pos/bg_final.jpg) no-repeat!important;
	background-position: bottom center!important;
    background-size: cover!important;
}
/*.bg2
{
	background: url(/assets/images/pos/2.png) no-repeat!important;
	background-position: bottom center!important;
    background-size: cover!important;
}
.bg3
{
	background: url(/assets/images/pos/3.png) no-repeat!important;
	background-position: bottom center!important;
    background-size: cover!important;
}
*/

.menu-reports-list{
  margin: 0;
  padding: 0;
}
.menu-reports-list a.md-button {
  display: block;
  padding: 0 16px 0 32px;
  text-align: left;
  font-size: 12px;
}
.side_reports_btn{
  margin-left: 5px !important;
}
.passanger_details table tr th{
  text-align: left;
  padding: 5px;
}
.passanger_details table{
  width:100%;
  background-color: #efefef;
}
.site_logo{
  /*width: 60px;*/
  height: 60px;
  text-align: center;
  /*margin-top: 10px;*/
}
.site_logo img{
  vertical-align: middle;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}


 @media all and (-ms-high-contrast:none)
     {
     #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button {  display: inline; } /* IE10 */
     *::-ms-backdrop, #horizontal-navigation .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-item .ms-navigation-horizontal-button {  display: inline; } /* IE11 */
     }