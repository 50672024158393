body {

    &.ms-info-bar-active {

        #main {
            top: 64px;
        }
    }
}

ms-info-bar {
    display: block;
    position: relative;
    width: 100%;
    height: 64px;
    background-color: material-color('pink');
    z-index: 99998;
    box-shadow: $whiteframe-shadow-2dp;

    #ms-info-bar-message {
        padding: 8px 16px;
        line-height: 48px;
        font-size: 16px;

        a {
            overflow: hidden;
            height: 48px;
            color: #FFFFFF;
        }
    }

    #ms-info-bar-close {
    }
}
