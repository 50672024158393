ms-masonry {
    width: 100%;
    display: block;

    ms-masonry-item,
    [ms-masonry-item] {
        transition: transform 300ms ease;
        position: absolute;
        transform: translate3d(50vw, 100vh, 0);

        &.placed {

        }
    }
}