p{
	margin:0;
}
.md_content{
	background-color:rgb(245,245,245) !important;
	margin: 0px 20px !important;
}
.perf_report_datepic .md-datepicker-input-container{
	width:83% !important;
}
.perf_selection_section .md-datepicker-input-container{
	width:83% !important;
}
@media screen and (max-width:1280px) and (min-width:960px){
	.perf_report_datepic .md-datepicker-input-container{
	width:70% !important;
}
.perf_selection_section .md-datepicker-input-container{
	width:70% !important;
}
}

.perf_selection_section .md-button.md-icon-button {
    margin: 0px ;
}
.perf_report_datepic .md-button.md-icon-button{
	margin:0px;
}

.header_banner{
	min-height: 100px !important;
	height:100px !important;
	background-color: rgb(204, 204, 204) !important;
	padding:18px !important;
}
.icon_color{
	color:white !important;
}
.datatable-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: #f9f9f9;
}
.select_content_center{
	justify-content: center;
}
.md_btn{
	margin:0 !important;
}
.sales_select_catagory{
	font-size:20px !important;
	color: #a9a9ba;
}
.banner_right_contents{
	color: #a9a9ba !important;
}
.header_banner span.title{
	color:#00b0c8;
}
.header_banner .breadcrumb{
	margin:0px !important;
}
.banner_right_links{
	color:#00b0c8 !important;
}
.md_btn_file{
	margin: 0px !important;
width: 35px !important;
padding: 2px !important;
}
.Additional_column_select{
	width: 160px !important;
}
/*.select_brand_select md-input-container{
	margin-top: 42px;
}*/
@media screen and (max-width: 599px){
	.select_brand_select md-input-container{
	margin: 0px;
}
.perf_report_datepic{
	margin-bottom: 30px;
	
}
}


.validation_err_msg{
	font-size: 12px;
color: red;
position: absolute;
padding:0px 49px;
}

table.perfor_report_table tbody td {
    padding: 5px 20px !important;    
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 0px solid !important;
    white-space: nowrap !important;
}
table.perfor_report_table thead tr th{
	min-width: 0px !important;
color: #fd4138;
text-transform: uppercase;
font-size: 14px;
padding: 10px 5px !important;
text-align: center;
}
.results_table_head_right{
	padding: 0px 10px !important;
}

.result_table_card{
	margin: 0px 8px !important;
}
.brand_label{
	min-width: 0px !important;
color: #fd4138;
text-transform: uppercase;
font-size: 14px;
display: inline-table;
width: 140px;
}
.dash_single_heading{
	margin:0 !important;
}
.card_maxheight{
	min-height: 85%;
}
.daily_sales_card_maxheight{
	min-height: 97%;
}
.category_based_sales_maxheight{
	min-height: 97%;
}
.sub_category_based_sales_maxheight{
	min-height: 97%;
}
.brand_based_sales_maxheight{
	min-height: 97%;
}
.annual_sales_maxheigth{
	min-height: 86%;
}
.select_brand_results p{
	margin:0;
	padding: 4px;
}
.overall_heading{
	margin: 0px 0px !important;
position: relative;
bottom: -20px;
font-size: 25px !important;
}
._md-select-value > span:not(._md-select-icon) ._md-text{
	padding-left: 6px;
}
.md-select-value > span:not(.md-select-icon) .md-text {
   
    padding-left: 6px !important;
}
text-center{
	text-align:center !important;
	
}
p.text-center{
	text-align:center !important;
	margin:0px;
}

.daily_sales_trend_title{
font-size: 14px;

text-align: left;
color: #00b0c8;
margin: 0px;
}
.gainer_loser_date_title{
	font-size: 16px;
margin: 0;
color: #00b0c8;
position: relative;

left: 17px;
width: 50%;
font-family: Noto Sans;
}
@media screen and (max-width:599px){
	.md_content {
   
    margin: 0px !important;
}
.md_content .form-wrapper{
	padding:10px !important;
	margin:5px 0px !important;
}
}
.growth_analysis_minheight{
	max-height:90%;
}
.gainer_loser_each_minheight{
	min-height:92%;
}
table.simple tbody tr td {
   
     border:none !important;
     padding:8px !important;
}
table.simple tbody tr td:last-child {
   
    border-right:none !important;
}
table.simple thead tr th{
	border-bottom:0px !important;
}
table.simple > tbody > tr:nth-of-type(2n+1) {
    background-color: #f9f9f9;
}
table.dataTable thead th{
	white-space: nowrap;
}
table.dataTable tbody td{
	white-space: nowrap !important;
}
.brand_select_input{
	position: relative;

	top: 25px;
}
.arrow_div{
	display: inline-flex;
}
.result_net_sales{
	margin:10px;
}
.result_net_sales md-radio-button{
	color: rgba(0,0,0,0.38);
}
.select_brand_results .brand_img_logo{
	max-width: 350px;
max-height: 150px;
}
.brand_img_div{
	text-align:center;
}
.no_sale_back_color{
	background-color:#ffe598;
}
.n_a_back_color{
	background-color:#fbe4d5;
}
.sc_temp_back_color{
	background-color:#5b9bd5;
}

.cons-sales-report-datepicker .md-datepicker-input-container {
    width: 81% !important;
}
@media screen and (max-width:959px) and (min-width:600px){
	.cons-sales-report-datepicker .md-datepicker-input-container {
    width: 90% !important;
}
}
@media screen and (max-width:599px) {
	.cons-sales-report-datepicker .md-datepicker-input-container {
    width: 80% !important;
}
}


.downloadpage{
	text-align: right;
}
.downloadpage_btn{
	height: 40px !important;
width: 35px !important;
padding: 0px !important;
border-radius: 0px !important;
margin-right: 8px !important;
}

#loader_section{
	  
    width: 100%;
    height: 100%;
    position: absolute;    
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.35);
    display:none;
}
#loader_section md-progress-circular{
	position: absolute;
top: 48%;
left: 48%;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00b0c8;
  border-bottom: 3px solid #00b0c8;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
     z-index: 11111111;
    margin: 20% auto;
    float: none;
    display: table;
  
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.excel-button {
  border: 0px solid #999 !important;
  background-color: transparent !important;
  background-image: none !important;
  float: right;
}
.pdf-button {
  border: 0px solid #999 !important;
  background-color: transparent !important;
  background-image: none !important;
  float: right;
}
#boards #board-selector .board-list .board-list-item {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    background-color: #fff !important;
    padding: 15px !important;
    margin: 16px !important;
    min-height: 300px;
}
.portfolio_image {
    width: 100%;
    height: 100px;
    text-align: center;
}
.portfolio_image img {
    width: auto;
    height: auto;
    max-height: 100%;
}
.title.h1 {
    color: #656565;
    font-size: 22px;
    text-align: center;
}
.text-wrap{
    white-space:normal;
}
/* For Manual Data Upload */
.manual-data-radiobtn {
   width: 40%;
   float: left;
   display: inline;
}
.manual-data-transaction-data-20{
       width: 20%;
}
.manual-data-transaction-data-15{
       width: 15%;
}
.manual-data-transaction-data-10{
       width: 10%;
}
.searchform-manual-data{
       width: 100%;
}
.amount-mt{
           margin: 24px 0 0 0;
}
.amount-transaction-right{
       text-align: right;
}
.inner-custom-horizontal { height: auto;
    overflow-y: auto;
    max-height: 400px;
}

/*#horizontal-navigation .layout-align-start-center .ms-navigation-horizontal .horizontal .ms-navigation-horizontal-node ul{
	height: auto;
    overflow-y: auto;
    max-height: 400px;
}*/

/*.inner-custom-horizontal { height: auto;
    overflow-y: auto;
    max-height: 400px;
}*/
/* div#content { height: 1000px; } */

/*.inner-custom-horizontal::-webkit-scrollbar {
    width: 12px;
}

.inner-custom-horizontal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.inner-custom-horizontal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

*/

/*.custom-horizontal-scroll{
	 background-color: #F5F5F5;
    float: left;
    height: 300px;
    margin-bottom: 25px;
    margin-left: 22px;
    margin-top: 40px;
    width: 65px;
       overflow-y: scroll;
}

.inner-custom-horizontal{
	 min-height: 450px;
}*/
/*.dash-perf-height{
	height: calc(100vh - 204px);
}*/

.space1 {padding: 32px 0;}

/*
::-webkit-scrollbar {
  width: 9px;
  height: 9px; }

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px; }

::-webkit-scrollbar-thumb {
  background: #f15d22bf;
  border: 0px none #ff8000;
  border-radius: 40px; }

::-webkit-scrollbar-thumb:hover {
  background: #f15d22d1; }

::-webkit-scrollbar-thumb:active {
  background: #f15d22d1; }

::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #400040;
  border-radius: 28px; }

::-webkit-scrollbar-track:hover {
  background: #ffffff; }

::-webkit-scrollbar-track:active {
  background: #ffffff; }

::-webkit-scrollbar-corner {
  background: transparent; }

*/

div.dt-buttons {
    position: relative;
    float: none;
}

#horizontal-navigation .ms-navigation-horizontal .horizontal .has-children:hover > div ul {
  display: block; 
}
#horizontal-navigation .ms-navigation-horizontal .horizontal > .has-children > div ul {
  top: 100%;
  left: 0; 
}
