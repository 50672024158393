
@import 'mixins.scss';
@import 'common.scss';

button.dt-button,
div.dt-button,
a.dt-button {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	margin-right: 0.333em;
	margin-bottom: 0.333em;
	padding: 0.5em 1em;
	border: 1px solid #999;
	border-radius: 2px;
	cursor: pointer;
	font-size: 0.88em;
	line-height: 1.6em;
	color: black;
	//box-shadow: 1px 1px 3px #ccc;
	white-space: nowrap;
	overflow: hidden;
	@include dtb-two-stop-gradient(white, #e9e9e9);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-decoration: none;
	outline: none;

	&.disabled {
		color: #999;
		border: 1px solid #d0d0d0;
		cursor: default;
		@include dtb-two-stop-gradient(#ffffff, #f9f9f9);
	}

	&:active:not(.disabled),
	&.active:not(.disabled) {
		@include dtb-two-stop-gradient(#f3f3f3, #e2e2e2);
		box-shadow: inset 1px 1px 3px #999999;

		&:hover:not(.disabled) {
			box-shadow: inset 1px 1px 3px #999999;
			@include dtb-two-stop-gradient(#eaeaea, #cccccc);
		}
	}

	&:hover {
		text-decoration: none;
	}

	&:hover:not(.disabled) {
		border: 1px solid #666;
		@include dtb-two-stop-gradient(#f9f9f9, #e0e0e0);
	}

	&:focus:not(.disabled) {
		border: 1px solid #426c9e;
		text-shadow: 0 1px 0 #c4def1;
		outline: none;
		@include dtb-two-stop-gradient(#bddef4, #79ace9);
	}
}


.dt-button embed { 
	outline: none;
}


div.dt-buttons {
	position: relative;
	float: left;

	&.buttons-right {
		float: right;
	}
}

div.dt-button-collection {
	position: absolute;
	top: 0;
	left: 0;
	width: 150px;
	margin-top: 3px;
	padding: 8px 8px 4px 8px;
	border: 1px solid #ccc;
	border: 1px solid rgba( 0, 0, 0, 0.4 );
	background-color: white;
	overflow: hidden;
	z-index: 2002;
	border-radius: 5px;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

	button.dt-button,
	div.dt-button,
	a.dt-button {
		position: relative;
		left: 0;
		right: 0;
		width: 100%;

		display: block;
		float: none;
		margin-bottom: 4px;
		margin-right: 0;

		&:active:not(.disabled),
		&.active:not(.disabled) {
			@include dtb-two-stop-gradient(#f0f0f0, #dadada);
			box-shadow: inset 1px 1px 3px #666;
		}
	}

	@include dtb-fixed-collection();
}

div.dt-button-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@include dtb-radial-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7) );

	z-index: 2001;
}

@media screen and (max-width: 640px) {
	div.dt-buttons {
		float: none !important;
		text-align: center;
	}
}

button.dt-button.processing,
div.dt-button.processing,
a.dt-button.processing {
	@include dtb-processing();
}
